export const enviroment = {
    // URL_API: 'http://127.0.0.1:8000/api/',
    URL_API: 'https://api.venelublara.com/public/api',
    // URL: 'http://127.0.0.1:8000',
    URL: 'https://api.venelublara.com/public',
    KEY_PUSHER: '51d42ba5f25cdb34e0a187aa6b7f1e7d7',
    APP_CLUSTER: 'mt1',
    // HOST_SOCKET: '127.0.0.1',
    HOST_SOCKET: 'api.venelublara.com',
};
